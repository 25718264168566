/* eslint no-shadow: ["warn", {
     "builtinGlobals": true,
     "allow": ["moment", "name", "status"]
   }] */

import React, { useEffect, useReducer, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { isArray, isEmpty, isNumber, isObject, map } from 'lodash'
import moment from 'moment'

import prayerListReducer from '../reducers/prayerListReducer'
import Prayer from '../models/Prayer'

// Import directly from the component files to avoid loading all of clover-ui
import ChevronDownIcon from 'clover-ui/icons/ChevronDownIcon'
import Popover from 'clover-ui/Popover'
import Tooltip from 'clover-ui/Tooltip'

import PrayerIcon from './PrayerIcon'

function ErrorMessage(props) {
  const { preview } = props
  return (
    <article className="sites-PrayerList__ErrorMessage">
      <div className="text-content">
        <p>Normally prayers would be shown here, but none could be loaded.</p>
        { preview &&
          <p><small>You may just need to finish setting up the current page in Greenhouse.<br />Or, there could have been an internal error, and you should contact Support.<br />(You are seeing this message because you are previewing your site.)</small></p>
        }
      </div>
    </article>
  )
}

function EmptyMessage(props) {
  const { cloverPrayersBase, preview } = props
  return (
    <article className="sites-PrayerList__EmptyMessage">
      <div className="text-content">
        <p>No prayers submitted to the public have been approved by our administrators yet.</p>
        { preview &&
          <p><small>In <a href={`${cloverPrayersBase}/overview`} className="open-link-in-greenhouse-tab">Clover Prayers</a>, review and choose to “Approve” prayers that have been submitted by your congregants; then they will appear here.<br />(You are seeing this message because you are previewing your site.)</small></p>
        }
      </div>
    </article>
  )
}

function PostDate(props) {
  const { date } = props
  const datetime = moment(date).format()
  const display = moment(date).format('MMM D, YYYY')
  return <time className="sites-PrayerList__PostDate"
               dateTime={datetime}>{display}</time>
}

function ContactInfoButton(props) {
  return (
    <button {...props} className="sites-PrayerList__ContactInfoButton">
      <span>Contact Info</span>
      <ChevronDownIcon height={24} width={24} />
    </button>
  )
}

function ContactPopover({ name, email, phone }) {
  const [opened, setOpened] = useState(false)

  function toggleOpen() {
    setOpened(!opened)
  }

  return (
    <Popover
      className="sites-PrayerList__ContactPopover"
      position="bottom"
      isOpen={opened}
      onClickOutside={() => setOpened(false)}
      content={
        <>
          <span>{name}</span>
          <span>{email}</span>
          { phone && <span>{phone}</span> }
        </>
      }
    >
      <ContactInfoButton onClick={toggleOpen} />
    </Popover>
  )
}

function PersonName() {
  return <span className="sites-PrayerList__PersonName"><i>Anonymous</i></span>
}

function Contact(props) {
  const { name, email } = props

  function shouldDisplayPopover() {
    return (name && email)
  }

  if (shouldDisplayPopover()) {
    return <ContactPopover {...props} />
  } else {
    return <PersonName />
  }
}

const isTouchDevice = (
  typeof document === 'object' &&
    document.documentElement &&
    'ontouchstart' in document.documentElement
)

function Acknowledgement({ count, acknowledgePrayer }) {
  const [isHovering, setIsHovering] = useState(false)

  function startedHovering() {
    setIsHovering(true)
  }

  function stoppedHovering() {
    setIsHovering(false)
  }

  return (
    <div className="sites-PrayerList__Acknowledgment">
      <span className="sites-PrayerList__AcknowledgmentCount">{count}</span>
      <Tooltip className="sites-PrayerList__ClickToPrayTooltip"
               position="top"
               isOpen={isHovering}
               content="Click to Pray">
        <button className="sites-PrayerList__Acknowledge"
                onClick={acknowledgePrayer}
                onFocus={startedHovering}
                onBlur={stoppedHovering}
                onMouseEnter={isTouchDevice ? undefined : startedHovering}
                onMouseLeave={isTouchDevice ? undefined : stoppedHovering}>
          <PrayerIcon />
        </button>
      </Tooltip>
    </div>
  )
}

function PrayerText({ prayer }) {
  return (
    <div className="text-content">
      <p className="sites-PrayerList__PrayerText">
        {prayer || <i>Prayer Confidential</i>}
      </p>
    </div>
  )
}

function PrayerItem(props) {
  const { data, links, meta,
          acknowledgePrayer: acknowledgePrayerAction } = props
  const { name, email, phone, prayer,
          acknowledgment_count: acknowledgementCount } = data
  const { acknowledge_prayer_link: acknowledgePrayerLink } = links
  const { posted_at: postedAt } = meta

  function acknowledgePrayer() {
    acknowledgePrayerAction(acknowledgePrayerLink)
  }

  return (
    <article className="sites-PrayerList__PrayerItem">
      <hr />
      <footer>
        <PostDate {...postedAt} />
        <Contact
          name={name}
          email={email}
          phone={phone} />
        <Acknowledgement
          count={acknowledgementCount}
          acknowledgePrayer={acknowledgePrayer} />
      </footer>
      <PrayerText prayer={prayer} />
    </article>
  )
}

export default function PrayerList(props) {
  const { prayerApiBase, preview, uuid } = props
  const [state, dispatch] = useReducer(prayerListReducer, {
    data: props.data,
    meta: props.meta
  })
  const { data, meta } = state

  async function fetchPage(page) {
    const response = await Prayer.fetch({ prayerApiBase, uuid, limit: 5, page })
    dispatch({ type: 'FETCH_PAGE', parameter: response })
  }

  async function acknowledgePrayer(link) {
    const response = await Prayer.acknowledge(link)
    dispatch({ type: 'ACKNOWLEDGE_PRAYER', parameter: response })
  }

  useEffect(() => {
    if (preview) return
    // Some prayers were server-rendered: awesome.  However, what if the set of
    // prayers that were server-rendered were cached and out-of-date with
    // respect to recently-approved prayers?  It could be frustrating to
    // customers to require they BOTH approve prayers AND publish their sites.
    // So refresh the list to spare them that requirement.
    fetchPage(1)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // Exhaustive deps checking is disabled above because this should only run
  // once on mount, and that’s it.

  const listRef = useRef(null)

  function scrollToTopOfList() {
    const $list = $(listRef.current)
    $list.trigger('prayer:list:scroll')
  }

  async function handlePageChange({ selected }) {
    await fetchPage(selected + 1)
    scrollToTopOfList()
  }

  const currentPage = isObject(meta) ? meta.current_page : undefined
  const lastPage = isObject(meta) ? meta.last_page : undefined

  return (
    <section ref={listRef} className="sites-PrayerList">
      { (!isArray(data) || !isObject(meta)) ? <ErrorMessage {...props} /> :
        isEmpty(data) ? <EmptyMessage {...props} /> :
        undefined }

      {map(data, (prayer) =>
        <PrayerItem key={prayer.data.uuid} {...prayer}
                    acknowledgePrayer={acknowledgePrayer} />
      )}

      { isNumber(lastPage) && lastPage !== 1 &&
        <footer className="sites-PrayerList__ListFooter">
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            pageCount={lastPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            forcePage={currentPage - 1}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"} />
        </footer>
      }
    </section>
  )
}
